import React from 'react';
import PropTypes from 'prop-types';
import { Toast } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import './BayToast.scss';
import BaySpinner from '../BaySpinner';

const BayToast = ({
	text,
	show,
	loading,
	className,
	variant,
	externalLinkText,
	delay,
	autoHide,
	dismissible,
	addLineBreak,
	onClose,
	onExternalLinkClick,
	...rest
}) => {
	const uid = Math.floor(Math.random() * 0x100000).toString(16);
	return (
		<Toast
			key={uid}
			bsPrefix="toast"
			animation
			autohide={autoHide}
			delay={delay}
			onClose={onClose}
			onExited={onClose}
			show={show}
			className={className ? className : ''}
			style={{ border: 'none' }}
			bg={variant}
			{...rest}
		>
			<Toast.Body bsPrefix="toast-body" className="fs-6">
				<div className="d-flex justify-content-between px-2">
					<div className="d-flex justify-content-center align-items-center text-wrap fs-6">
						{loading && <BaySpinner title={' '} />}
						<p className="mb-0">
							{text}
							{externalLinkText && (
								<>
									{addLineBreak && <br />}
									<span
										className={`text-decoration-underline cursor-pointer ${
											addLineBreak ? false : 'mx-1'
										}`}
										style={{ textUnderlinePosition: 'under' }}
										onClick={onExternalLinkClick}
									>
										{externalLinkText}
									</span>
								</>
							)}
						</p>
					</div>
					{dismissible && (
						<div onClick={onClose} className="cursor-pointer">
							<AiOutlineClose size={18} />
						</div>
					)}
				</div>
			</Toast.Body>
		</Toast>
	);
};

BayToast.defaultProps = {
	text: 'Bay toast',
	variant: 'default',
	delay: 5000,
	autoHide: true,
	dismissible: true,
	addLineBreak: false,
};

BayToast.propTypes = {
	text: PropTypes.string,
	show: PropTypes.bool,
	loading: PropTypes.bool,
	variant: PropTypes.string,
	className: PropTypes.string,
	externalLinkText: PropTypes.string,
	delay: PropTypes.number,
	autoHide: PropTypes.bool,
	dismissible: PropTypes.bool,
	addLineBreak: PropTypes.bool,
	onClose: PropTypes.func,
	onExternalLinkClick: PropTypes.func,
};

export default BayToast;
