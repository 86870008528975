import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const BaySpinner = ({ title, className, variant, textColor }) => {
	const classes = classNames([
		'bay-add-button',
		 className,
	]);

	return (
		<div
			className={`d-flex align-items-center justify-content-center ${classes}`}
		>
			<Spinner
				animation="border"
				variant={variant ? variant : 'secondary'}
				style={{ width: '20px', height: '20px' }}
			/>
			<div
				className={`ms-2 ${textColor ? textColor : 'text-700'} fw-bold`}
			>
				{title ? title : 'Loading Data...'}
			</div>
		</div>
	);
};

BaySpinner.propTypes = {
	title: PropTypes.string,
	className: PropTypes.string,
	variant: PropTypes.string,
	textColor: PropTypes.string
};

export default BaySpinner;
