import BayToast from './BayToast';
import BayToastNew from './BayToastNew';
import { useToast } from './BayToastNew.hook';

BayToast.useToast = useToast;
BayToastNew.useToast = useToast;

export { BayToastNew, useToast };

export default BayToast;
