import React, { useState, useCallback } from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-bootstrap';
import BayToast from './BayToastNew';

const createRoot = () => {
	const toastRoot = document.createElement('div');
	document.body.appendChild(toastRoot);
	toastRoot.id = 'toast-root';
	const root = ReactDOM.createRoot(toastRoot);
	return root;
};

window._toasts_root = !window._toasts_root ? createRoot() : window._toasts_root;
window._toasts_nextId = !window._toasts_nextId ? 1 : window._toasts_nextId;
window._toasts = !window._toasts ? [] : window._toasts;

export const useToast = ({ position = 'top-end' } = {}) => {
	const [_, update] = useState(false);

	const generateUniqueId = () => window._toasts_nextId++;

	const showToast = useCallback((options) => {
		const id = generateUniqueId();
		const {
			text,
			variant = 'default',
			externalLinkText,
			onExternalLinkClick,
			delay = 5000,
			autoHide = true,
			dismissible = true,
			addLineBreak = false,
			...rest
		} = options;

		const toast = {
			id,
			show: true,
			text,
			variant,
			externalLinkText,
			onExternalLinkClick,
			delay,
			autoHide,
			dismissible,
			addLineBreak,
			...rest,
		};

		window._toasts = [...window._toasts, toast];
		update((prev) => !prev);

		if (autoHide) {
			setTimeout(() => {
				hideToast(id);
			}, delay);
		}

		return id;
	}, []);

	const hideToast = useCallback((id) => {
		window._toasts = window._toasts.map((toast) =>
			toast.id === id ? { ...toast, show: false } : toast,
		);
		update((prev) => !prev);
	}, []);

	const removeToast = useCallback((id) => {
		window._toasts = window._toasts.filter((toast) => toast.id !== id);
		update((prev) => !prev);
	}, []);

	window._toasts_root.render(
		<ToastContainer
			position={position}
			className={`position-fixed rounded p-3`}
			bsPrefix="toast-container"
		>
			{window._toasts.map((toast) => {
				return (
					<BayToast
						{...toast}
						key={toast.id}
						show={toast.show}
						text={toast.text}
						variant={toast.variant}
						externalLinkText={toast.externalLinkText}
						onExternalLinkClick={toast.onExternalLinkClick}
						delay={toast.delay}
						autoHide={toast.autoHide}
						dismissible={toast.dismissible}
						addLineBreak={toast.addLineBreak}
						onClose={() => hideToast(toast.id)}
						onExited={() => removeToast(toast.id)}
						className={`bay-toast-variant-${toast.variant}`}
					/>
				);
			})}
		</ToastContainer>,
	);

	return { showToast, hideToast };
};
