import axios from "axios";
import { isNil, isEmpty } from "lodash";
import { DESIGN_API_URL } from "../../lib/environment.js";

import { newAbortSignalFunc } from "../api";
import { escapeRegExp } from '../utils';

const templates = () => {
	let templatesCancelToken = axios.CancelToken.source();
	let timeoutAmount = 180000; //Aborts request after 30 seconds

  return {
		cancel: () => {
			templatesCancelToken.cancel('Operation canceled by the user');
			templatesCancelToken = axios.CancelToken.source();
		},
    create: (data) => {
      return new Promise((resolve, reject) => {
        const controller = new AbortController();

        let timeout = setTimeout(() => {
          controller.abort();
        }, 180000);

        axios({
          url: `${DESIGN_API_URL}/templates`,
          method: "POST",
          cache: "no-cache",
          data: data,
          signal: controller.signal,
        })
          .then((resp) => {
            clearTimeout(timeout);

            resolve(resp.data.result);
          })
          .catch((err) => {
            clearTimeout(timeout);

            reject(err);
          });
      });
    },

    read: (readArgs) => {
      return new Promise((resolve, reject) => {
				const {
					templateId = null,
					queryParams,
					limit = 24,
					offset = 0,
					sort = {"dtUpd":"desc"},
					includeHidden = false,
					havingAnyOfOwners = null,
					havingAllOfTags$Category = null,
					havingAllOfTags$General = null
				} = readArgs;

				let timeout = setTimeout(() => {
          controller.abort();
        }, 180000);

				// isUncategorized
				const params = new URLSearchParams({
						q: JSON.stringify({...queryParams, isHidden:includeHidden}),
						limit,
						offset,
						sort: JSON.stringify(sort),
						//havingAnyOfOwners: JSON.stringify(havingAnyOfOwners)
        });

				if (havingAllOfTags$Category && havingAllOfTags$Category?.length > 0) {
					params.append('havingAllOfTags$Category', JSON.stringify(havingAllOfTags$Category));
				}

				if (havingAllOfTags$General && havingAllOfTags$General?.length > 0) {
					params.append('havingAllOfTags$General', JSON.stringify(havingAllOfTags$General));
				}

        if ((isNil(templateId) || isEmpty(templateId))) {
          axios({
            url: `${DESIGN_API_URL}/templates/?${params.toString()}`,
            method: "GET",
            cache: "no-cache",
            signal: newAbortSignalFunc(timeoutAmount),
						cancelToken: templatesCancelToken.token
          })
            .then((resp) => {
              clearTimeout(timeout);

              if (resp.data.status === 0) {
                resolve(resp.data);
              } else {
                reject(resp.data);
              }
            })
            .catch((err) => {
              clearTimeout(timeout);

              reject(err);
            });
        } else {
          axios({
            url: `${DESIGN_API_URL}/templates/${templateId}`,
            method: "GET",
            cache: "no-cache",
						signal: newAbortSignalFunc(timeoutAmount),
						cancelToken: templatesCancelToken.token
          })
            .then((resp) => {
              clearTimeout(timeout);

              if (resp.data.status === 0) {
                resolve(resp.data.result);
              } else {
                reject(resp.data);
              }
            })
            .catch((err) => {
              clearTimeout(timeout);

              reject(err);
            });
        }
      });
    },

    update: (templateId, templateData) => {
      return new Promise((resolve, reject) => {
        const controller = new AbortController();

        let timeout = setTimeout(() => {
          controller.abort();
        }, 180000);

        axios({
          url: `${DESIGN_API_URL}/templates/${templateId}`,
          method: "PATCH",
          cache: "no-cache",
          data: templateData,
          signal: controller.signal,
        })
          .then((resp) => {
            clearTimeout(timeout);

            resolve(resp.data.result);
          })
          .catch((err) => {
            clearTimeout(timeout);

            reject(err);
          });
      });
    },

    delete: (templateId) => {
      return new Promise((resolve, reject) => {
        const controller = new AbortController();

        let timeout = setTimeout(() => {
          controller.abort();
        }, 180000);

        axios({
          url: `${DESIGN_API_URL}/templates/${templateId}`,
          method: "DELETE",
          cache: "no-cache",
          signal: controller.signal,
        })
          .then((resp) => {
            clearTimeout(timeout);

            if (resp.data.status === 0) {
              resolve(resp.data.result);
            } else {
              reject(resp.data);
            }
          })
          .catch((err) => {
            clearTimeout(timeout);

            reject(err);
          });
      });
    },

    archive: (templateId) => {
      return this.update(templateId, { status: "archived" });
    },

    hideAllBayTemplates: (hideNonproprietaryTemplates) => {
      return new Promise((resolve, reject) => {
        const controller = new AbortController();

        let timeout = setTimeout(() => {
          controller.abort();
        }, 180000);

        axios({
          url: `${DESIGN_API_URL}/customers/CURRENT/prefs`,
          method: "PATCH",
          cache: "no-cache",
          data: {
            hideNonproprietaryTemplates: hideNonproprietaryTemplates,
          },
          signal: controller.signal,
					cancelToken: templatesCancelToken.token
        })
          .then((resp) => {
            clearTimeout(timeout);
            resolve(resp.data);
          })
          .catch((err) => {
            clearTimeout(timeout);
            reject(err);
          });
      });
    },

    setVisibility: (templateId, visibility) => {
      return new Promise((resolve, reject) => {
        const controller = new AbortController();

        let timeout = setTimeout(() => {
          controller.abort();
        }, 180000);

        axios({
          url: `${DESIGN_API_URL}/templates/${templateId}/prefsOf.customers/CURRENT`,
          method: "PATCH",
          data: {
            hide: visibility,
          },
          cache: "no-cache",
          signal: controller.signal,
        })
          .then((resp) => {
            clearTimeout(timeout);

            if (resp.data.status === 0) {
              resolve(resp.data);
            } else {
              reject(resp.data);
            }
          })
          .catch((err) => {
            clearTimeout(timeout);

            reject(err);
          });
      });
    },

    uiSupport: (ownerId = null) => {
      return new Promise((resolve, reject) => {
        const controller = new AbortController();

        let timeout = setTimeout(() => {
          controller.abort();
        }, 180000);

        // template / templateKey / prefs
        axios({
          url: `${DESIGN_API_URL}/templates/NEW${ownerId ? `?owner=${ownerId}` : ""}`,
          method: "GET",
          cache: "no-cache",
          signal: controller.signal,
					cancelToken: templatesCancelToken.token
        })
          .then((resp) => {
            clearTimeout(timeout);

            if (resp.data.status === 0) {
              resolve(resp.data);
            } else {
              reject(resp.data);
            }
          })
          .catch((err) => {
            clearTimeout(timeout);

            reject(err);
          });
      });
    },

    getQueryParams: (user, params, isSuperRole) => {

			let ownerArray = params?.owner && params?.owner !== ""
			? [params?.owner && params?.owner === "0" ? 0 : params?.owner]
			: [user?.customer?._id, 0];

			let getStatusArray = (idNumber, ownerArrayItem, status) => {
				let constructedQuery = '';

				if (ownerArrayItem !== 0){
          constructedQuery = status === "" ? { "$in": ["active", "draft"]} : status
				} else {
					if (status === ""){
						// "draft", "inactive", "review", "archived"
						constructedQuery = idNumber === 0 ? { "$in": ["active", "draft", "inactive"]} : "active"
					} else {
						constructedQuery = idNumber === 0 ? status : 'active'
					}
				}

        return constructedQuery;
      }

      let sp = {
        search: (params?.search ? params.search.split(' ').map((e) => escapeRegExp(e)).join('.*') : ""),
        status: params?.status || "",
      };

      let queryParams = {
				variant: 'design',
			};

			if (!isSuperRole) {

				if (ownerArray.length === 1) {
					queryParams = {
						...queryParams,
						$and: [
              {
                [ownerArray[0] === 0 ? "owner.idNumber" : "owner._id"]: ownerArray[0]
              },
              {
                status: getStatusArray(user?.customer?.idNumber, ownerArray[0], sp.status),
              }
            ],
					}
				} else {
					queryParams["$or"] = [];

					for (const ownerArrayItem of ownerArray) {
						queryParams["$or"].push({
							$and: [
								{
									[ownerArrayItem === 0 ? "owner.idNumber" : "owner._id"]: ownerArrayItem
								},
								{
									status: getStatusArray(user?.customer?.idNumber, ownerArrayItem, sp.status),
								}
							],
						});
					}
				}
			} else if (isSuperRole) {
				queryParams = {
					...queryParams,
					[
						ownerArray.length === 1 ? "$and" : "status"]: ownerArray.length === 1 ? [
						{
							[ownerArray[0] === 0 ? "owner.idNumber" : "owner._id"]: ownerArray[0]
						},
						{
							status: sp.status === "" ? { "$in": ["active", "draft", "inactive", "review", "archived"]} : sp.status,
						}
					] : sp.status === "" ? { "$in": ["active", "draft", "inactive", "review", "archived"]} : sp.status
				}
			}

      if (!isNil(sp.search) && sp.search !== "") {
        queryParams.name = {
          $regex: `${sp.search}`,
          $options: "i",
        };
      }

      if (!isNil(sp.sortBy) && !isNil(sp.sortDir)) {
        queryParams[sp.sortBy] = sp.sortDir;
      }

      if (!isNil(sp.limit)) {
        queryParams.limit = sp.limit;
      }

      if (!isNil(sp.offset)) {
        queryParams.offset = sp.offset;
      }

      return queryParams;
    },
    readCategoryTemplates: (categoryName, customerId) => {
      return new Promise((resolve, reject) => {
        const controller = new AbortController();

        let timeout = setTimeout(() => {
          controller.abort();
        }, 180000);

        // NOTE: ["0::${categoryName}"] is a another way to get the category templates
        //"owner": "0" customerId,
        axios({
          url: `${DESIGN_API_URL}/templates?havingAllOfTags$Category=["${customerId}::${categoryName}"]`,
          method: "GET",
          cache: "no-cache",
          signal: controller.signal,
          /* params: {
            havingAllOfTags$Category: JSON.stringify({
              "name": categoryName,
            }),
          } */
        })
          .then((resp) => {
            clearTimeout(timeout);

            if (resp.data.status === 0) {
              resolve(resp.data);
            } else {
              reject(resp.data);
            }
          })
          .catch((err) => {
            clearTimeout(timeout);

            reject(err);
          });
      });
    }
  };
};

const singleton = templates();
Object.freeze(singleton);

export default singleton;
